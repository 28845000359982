<template>
    <div>
        <b-tabs
            vertical
            content-class="col-12 col-md-9 mt-1 mt-md-0"
            pills
            no-nav-style
            nav-wrapper-class="col-md-3 col-12"
            nav-class="nav-left"
        >

        <b-tab active>
            <template #title>
                <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                    <div class="ren-title-img">
                        <RtIcon :size="21" variant="light" icon="gear"/>
                    </div>
                    <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Configuration`)}}</strong>
                        </p>
                        <p v-if="false" class="m-0 text-secondary">
                            {{ i18nT(`Type, noms, addresse`)}}
                        </p>
                    </div>
                </div>
            </template>
            <b-card>
                <b-card-header class="p-0">
                    <h2>{{i18nT(`Configuration`)}}</h2>
                </b-card-header>
                <b-row class="border-bottom">
                    <b-col cols="12">
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Langue`)"
                                >
                                    <v-select
                                        v-model="landlordData.LandlordSiteLang"
                                        :options="locales"
                                        label="text"
                                        :clearable="false"
                                        :reduce="option => option.value"
                                        :placeholder="i18nT(`Choisir`)"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Affichage des noms`)"
                                >
                                    <v-select
                                        v-model="nameFormat"
                                        :options="nameFormats"
                                        label="text"
                                        :clearable="false"
                                        :placeholder="i18nT(`Choisir`)"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Pied de page`)"
                                >
                                    <b-form-textarea
                                        :placeholder="i18nT(`La présente quittance est délivrée sous réserve de tout droit, action ou procédure quelconques et, en cas de paiement par chèque, sous condition suspensive de son encaissement. Elle ne présume pas du paiement des quittances antérieures ou toute autre somme due. Cette quittance doit avoir le tampon et la signature pour être valable.`)"
                                        id='Private note'
                                        size="sm"
                                        rows="4"
                                        v-model="landlordData.LandlordFooter"
                                    />
                                    <small>{{ i18nT(`Laisser vide pour utiliser le pied de page par défaut`) }}</small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" class="float-right">
                        <div class="text-right m-1">
                            <b-button
                                variant="outline-secondary"
                                class="mb-1 mr-1"
                                :to="{ name: 'tenants'}"
                            >
                                {{i18nT(`Annuler`)}}
                            </b-button>
                            <b-button
                                variant="primary"
                                class="mb-1"
                                @click.prevent="submitSettings"
                            >
                                {{i18nT(`Sauvegarder`)}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                    <div class="ren-title-img">
                        <RtIcon :size="21" variant="light" icon="calendar-clock"/>
                    </div>
                    <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Date, heure et unités`)}}</strong>
                        </p>
                        <p v-if="false" class="m-0 text-secondary">
                            {{ i18nT(`Type, noms, addresse`)}}
                        </p>
                    </div>
                </div>
            </template>
            <b-card>
                <b-card-header class="p-0">
                    <h2>{{i18nT(`Date, heure et unités`)}}</h2>
                </b-card-header>
                <b-row class="border-bottom">
                    <b-col cols="12" class="my-1">
                        <b-row>
                            <b-col
                                cols="12"
                            >
                                <b-row class="m-1">
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Format de date`)"
                                        >
                                            <v-select
                                                v-model="dateFormat"
                                                :options="dateFormats"
                                                label="text"
                                                :clearable="false"
                                                :placeholder="i18nT(`Choisir`)"
                                                :reduce="option=>option.value"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="m-1">
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Format de l'heure`)"
                                        >
                                            <v-select
                                                v-model="timeFormat"
                                                :options="timeFormats"
                                                label="text"
                                                :clearable="false"
                                                :placeholder="i18nT(`Choisir`)"
                                                :reduce="option=>option.value"

                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-row class="m-1">
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Unité de mesure`)"
                                        >
                                            <v-select
                                                v-model="landlordData.LandlordMeasureUnits"
                                                :options="unitFormats"
                                                label="text"
                                                :clearable="false"
                                                :placeholder="i18nT(`Choisir`)"
                                                :reduce="option=>option.value"
                                            />
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>

                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" class="float-right">
                        <div class="text-right m-1">
                            <b-button
                                variant="outline-secondary"
                                class="mb-1 mr-1"
                                :to="{ name: 'tenants'}"
                            >
                                {{i18nT(`Annuler`)}}
                            </b-button>
                            <b-button
                                variant="primary"
                                class="mb-1"
                                @click.prevent="submitSettings"
                            >
                                {{i18nT(`Sauvegarder`)}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                    <div class="ren-title-img">
                        <RtIcon :size="21" variant="light" icon="money-check-pen"/>
                    </div>
                    <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Comptabilité`)}}</strong>
                        </p>
                        <p v-if="false" class="m-0 text-secondary">
                            {{ i18nT(`Type, noms, addresse`)}}
                        </p>
                    </div>
                </div>
            </template>
            <b-card>
                <b-card-header class="p-0">
                    <h2>{{i18nT(`Comptabilité`)}}</h2>
                </b-card-header>
                <b-row class="border-bottom">
                    <b-col cols="12">
                        <b-row class="ml-1 mt-1">
                            <b-col cols="12" md="6" >
                                <b-form-group
                                    :label="i18nT(`Année fiscale`)"
                                >
                                    <div class="d-flex">
                                        <v-select
                                            v-model="fiscalMonth"
                                            :options="fiscalMonths"
                                            label="text"
                                            :clearable="false"
                                            class="pr-1 w-100"
                                            :placeholder="i18nT(`Choisir`)"
                                        />
                                        <b-form-input
                                            id="type-number"
                                            type="number"
                                            v-model="landlordData.LandlordBilanStartDay"
                                            class="w-25"
                                        />
                                    </div>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="ml-1">
                            <b-col cols="12" class="my-1">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Code QR`)"
                                            class="mb-0"
                                        >
                                            <b-form-checkbox
                                                name="check-button"
                                                switch
                                                v-model='showQR'
                                                class="mb-50"
                                            >
                                            </b-form-checkbox>
                                            <small>{{ i18nT(`Afficher le code QR sur les quittances`) }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="ml-1">
                            <b-col cols="12" class="my-1">
                                <b-row>
                                    <b-col cols="12" md="6">
                                        <b-form-group
                                            :label="i18nT(`Solde locataire`)"
                                        >
                                            <b-form-checkbox
                                                name="check-button"
                                                switch
                                                v-model='showBalance'
                                                class="mb-50"
                                            >
                                            </b-form-checkbox>
                                            <small>{{
                                                    i18nT(`Afficher le Solde locataire sur votre Quittance de loyer et Avis d'échéance`)
                                                }}</small>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="ml-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Devise`)"
                                >
                                    <v-select
                                        v-model="landlordData.LandlordCurrency"
                                        :options="currencies"
                                        label="text"
                                        :clearable="false"
                                        :reduce="option => option.value"
                                        :palceholder="i18nT(`Choisir`)"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="ml-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Multi devise`)"
                                >
                                    <b-form-checkbox
                                        name="check-button"
                                        switch
                                        v-model='multiCurrency'
                                        class="mb-50"
                                    >
                                    </b-form-checkbox>
                                    <small>{{
                                            i18nT(`Activer la possibilité de choisir une devise différente pour chaque bien immobilier`)
                                        }}</small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" class="float-right">
                        <div class="text-right m-1">
                            <b-button
                                variant="outline-secondary"
                                class="mb-1 mr-1"
                                :to="{ name: 'tenants'}"
                            >
                                {{i18nT(`Annuler`)}}
                            </b-button>
                            <b-button
                                variant="primary"
                                class="mb-1"
                                @click.prevent="submitSettings"
                            >
                                {{i18nT(`Sauvegarder`)}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                    <div class="ren-title-img">
                        <RtIcon :size="21" variant="light" icon="list-ul"/>
                    </div>
                    <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Rubriques`)}}</strong>
                        </p>
                        <p v-if="false" class="m-0 text-secondary">
                            {{ i18nT(`Type, noms, addresse`)}}
                        </p>
                    </div>
                </div>
            </template>
            <b-card>
                <b-card-header class="p-0">
                    <h2>{{i18nT(`Rubriques`)}}</h2>
                </b-card-header>
                <b-row class="border-bottom">
                    <b-col cols="12">
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                    :label="i18nT(`Mode expert`)"
                                >
                                    <b-form-checkbox
                                        name="check-button"
                                        switch
                                        v-model='expertMode'
                                        class="mb-50"
                                    >
                                    </b-form-checkbox>
                                    <small>{{ i18nT(`Activer ou désactiver le mode expert. Le mode expert active les fonctionnalités avancées du logiciel.`) }}</small>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <p>{{i18nT(`Vous pouvez activer ou désactiver des rubriques du site. Ceci modifie l'affichage du menu principal.`)}}</p>
                                <b-form-group>
                                    <b-form-checkbox
                                        v-for="(option, index) in sectionColumns"
                                        :key="'section-'+index"
                                        v-model="selectedItems"
                                        :disabled="option.disabled"
                                        inline
                                        class="rt-custom-checkbox ml-1"
                                    >
                                        <div class="d-flex"><span class="mr-1">{{ i18nT(option.text) }}</span>
                                            <b-badge v-if="option.disabled" class="badge-pill"
                                                     variant="light-secondary">
                                                {{ i18nT(`Par défault`) }}
                                            </b-badge>
                                        </div>
                                    </b-form-checkbox>
                                </b-form-group>
                                <!--                                <b-form-group-->
                                <!--                                    v-slot="{ ariaDescribedby }"-->
                                <!--                                >-->
                                <!--                                    <b-form-checkbox-->
                                <!--                                        v-for="item in sectionColumnOne"-->
                                <!--                                        v-model="selectedItems"-->
                                <!--                                        :key="item.value"-->
                                <!--                                        :value="item.value"-->
                                <!--                                        :disabled="item.disabled"-->
                                <!--                                        class="m-1"-->
                                <!--                                    >-->
                                <!--                                        <span class="mr-1">{{ i18nT(item.text) }}</span>-->
                                <!--                                        <b-badge v-if="item.disabled" class="badge-pill" variant="light-secondary">-->
                                <!--                                            {{ i18nT(`Par défault`) }}-->
                                <!--                                        </b-badge>-->
                                <!--                                    </b-form-checkbox>-->
                                <!--                                </b-form-group>-->

                                <!--                            </b-col>-->
                                <!--                            <b-col cols="12" md="6" class="mt-1">-->
                                <!--                                <b-form-group-->
                                <!--                                    v-slot="{ ariaDescribedby }"-->
                                <!--                                >-->
                                <!--                                    <b-form-checkbox-->
                                <!--                                        v-for="item in sectionColumnTwo"-->
                                <!--                                        v-model="selectedItems"-->
                                <!--                                        :key="item.value"-->
                                <!--                                        :value="item.value"-->
                                <!--                                        :disabled="item.disabled"-->
                                <!--                                        class="m-1"-->
                                <!--                                    >-->
                                <!--                                        <span class="mr-1">{{ i18nT(item.text) }}</span>-->
                                <!--                                        <b-badge v-if="item.disabled" class="badge-pill" variant="light-secondary">-->
                                <!--                                            {{ i18nT(`Par défault`) }}-->
                                <!--                                        </b-badge>-->
                                <!--                                    </b-form-checkbox>-->
                                <!--                                </b-form-group>-->
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" class="float-right">
                        <div class="text-right m-1">
                            <b-button
                                variant="outline-secondary"
                                class="mb-1 mr-1"
                                :to="{ name: 'tenants'}"
                            >
                                {{i18nT(`Annuler`)}}
                            </b-button>
                            <b-button
                                variant="primary"
                                class="mb-1"
                                @click.prevent="submitSettings"
                            >
                                {{i18nT(`Sauvegarder`)}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-tab>
        <b-tab>
            <template #title>
                <div class="d-flex align-items-center mb-1 mr-1 ren-title">
                    <div class="ren-title-img">
                        <RtIcon :size="21" variant="light" icon="bell"/>
                    </div>
                    <div>
                        <p class="m-0">
                            <strong>{{ i18nT(`Notifications`)}}</strong>
                        </p>
                        <p v-if="false" class="m-0 text-secondary">
                            {{ i18nT(`Type, noms, addresse`)}}
                        </p>
                    </div>
                </div>
            </template>
            <b-card>
                <b-card-header class="p-0">
                    <h2>{{i18nT(`Notifications`)}}</h2>
                </b-card-header>
                <b-row class="border-bottom">
                    <b-col cols="12">
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                >
                                    <b-form-checkbox
                                        v-for="(notification, index) in notifications"
                                        :key="'notification-'+index"
                                        v-model="selectedNotifications"
                                        :value="notification.value"
                                        :disabled="notification.disabled"
                                        class="m-1"
                                    >{{i18nT(notification.text)}}</b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-1">
                            <b-col cols="12" md="6">
                                <b-form-group
                                >
                                    <b-form-checkbox
                                        v-for="(offer, index) in offers"
                                        :key="'notification-'+index"
                                        v-model="selectedOffers"
                                        :value="offer.value"
                                        :disabled="offer.disabled"
                                        class="m-1"
                                    >{{i18nT(offer.text)}}</b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12" class="float-right">
                        <div class="text-right m-1">
                            <b-button
                                variant="outline-secondary"
                                class="mb-1 mr-1"
                                :to="{ name: 'tenants'}"
                            >
                                {{i18nT(`Annuler`)}}
                            </b-button>
                            <b-button
                                variant="primary"
                                class="mb-1"
                                @click.prevent="submitSettings"
                            >
                                {{i18nT(`Sauvegarder`)}}
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </b-card>
        </b-tab>
        </b-tabs>
    </div>

</template>


<script>
import {
    BCard,
    BFormGroup,
    BFormTextarea,
    BRow,
    BCol,
    BFormCheckbox,
    BFormInput,
    BBadge,
    BTabs,
    BTab,
    BCardHeader,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {dictToSelectArray} from "@core/utils/utils";
import useAuth from '@/auth/useAuth';
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {localize} from "vee-validate";



export default{
    components: {
        BFormCheckbox,
        BFormGroup,
        BCard,
        BRow,
        BCol,
        BFormTextarea,
        vSelect,
        BFormInput,
        BBadge,
        BTabs,
        BTab,
        BCardHeader,
    },
    data(){
        return{
            landlordData: {},
            showQR: false,
            showBalance: false,
            nameFormat: {value: '-1', text: 'Choisir'},
            nameFormats: [],
            currency: {value: '-1', text: 'Choisir'},
            currencies: [],
            multiCurrency: false,
            locale: {value: '-1', text: 'Choisir'},
            locales: [],
            dateFormat: {value: '-1', text: 'Choisir'},
            dateFormats: [],
            timeFormat: {value: '-1', text: 'Choisir'},
            timeFormats: [],
            unitFormat: {value: '-1', text: 'Choisir'},
            unitFormats: [],
            fiscalMonth: {value: '-1', text: 'Choisir'},
            fiscalMonths: [],
            fiscalNumber: 1,
            expertMode: false,
            sectionColumns: [
                {text: 'Bureau', value: 'office', disabled: true},
                {text: 'Locataires', value: 'tenants', disabled: true},
                {text: 'Agenda', value: 'agenda', disabled: false},
                {text: 'Agenda', value: 'agenda2', disabled: false},
                {text: 'Agenda', value: 'agenda3', disabled: false},
                {text: 'Biens', value: 'properties', disabled: true},
                {text: 'Locations', value: 'tenancies', disabled: true},
                {text: 'Reservations', value: 'reservations', disabled: false},
                {text: 'Agenda', value: 'agenda4', disabled: false},
                {text: 'Agenda', value: 'agenda5', disabled: false},],
            selectedItems: [],
            notifications: [
                {text: "Notifiez moi dès la reception d'un message", value: 'LandlordReceiveMessageAlerts', disabled: false},
                {text: "Notifiez moi dès la réception d'une candidature", value: 'LandlordNewCandidateAlerts', disabled: false},
                {text: "Notifiez moi dès la création d'une tâche", value: 'LandlordNewTaskAlerts', disabled: false},
                {text: "Notifiez moi dès la création d'une intervention", value: 'LandlordNewInterventionAlerts', disabled: false},
                {text: "Notifiez moi dès la création d'une location", value: 'LandlordNewLeaseAlerts', disabled: false},
                {text: "Notifiez moi dès la création d'une réservation", value: 'LandlordNewReservationAlerts', disabled: false},
                {text: "Notifiez moi dès la création d'un événement", value: 'LandlordNewAgendaAlerts', disabled: false},
                {text: "Notifiez moi des loyers en retard", value: 'LandlordNotifyLateRents', disabled: false},
            ],
            selectedNotifications: [],
            offers: [
                {text: 'Recevoir notre Newsletter mensuelle', value: 'LandlordReceiveOffers', disabled: false},
                {text: 'Recevoir nos offres partenaires', value: 'LandlordReceivePartnerOffers', disabled: false}
            ],
            selectedOffers: [],
        }
    },
    created(){
        this.$http.get('landlord/profile/settings').then(({data})=>{
            this.landlordData= data.data.user;
            this.dateFormat = data.data.user.LandlordDateFormat;
            this.timeFormat = data.data.user.LandlordTimeFormat;

            this.notifications.map(notification=> this.landlordData[notification.value] ? this.selectedNotifications.push(notification.value) : null)
            this.offers.map(offer=> this.landlordData[offer.value] ? this.selectedOffers.push(offer.value) : null)
            this.showQR = this.landlordData.LandlordShowCodeQR ? true : false;
            this.showBalance = this.landlordData.LandlordShowSolde ? true : false;
            this.expertMode = this.landlordData.LandlordExpertMode ? true : false;
            this.multiCurrency = this.landlordData.LandlordMultiCurrency ? true : false;


            this.dateFormats = dictToSelectArray(data.data.lists['date_formats']);

            this.nameFormats = data.data.lists['inverse_names'];
            this.nameFormat = this.landlordData.LandlordInverseNames ? this.nameFormats[1] : this.nameFormats[0];

            this.locales= dictToSelectArray(data.data.lists['languages']);
            this.timeFormats = dictToSelectArray(data.data.lists['time_formats']);
            this.unitFormats = dictToSelectArray(data.data.lists['measure_units']);
            this.fiscalMonths = data.data.lists['months'].slice(1);
            this.fiscalMonth= this.fiscalMonths[this.landlordData.LandlordBilanStartMonth - 1];
            this.currencies = dictToSelectArray(data.data.lists['currencies']);


        });

        this.selectedItems = this.sectionColumns
            .filter(item => item.disabled !== false)
            .map(item => item.value);
    },
    methods:{
        submitSettings(){

            const formData = new FormData();
            //
            // formData.append('id', this.landlordData.LandlordID)
            formData.append('LandlordFooter', this.landlordData.LandlordFooter);
            formData.append('LandlordShowCodeQR', this.showQR ? 1 : 0);
            formData.append('LandlordShowSolde', this.showBalance ? 1 : 0);
            formData.append('LandlordInverseNames', this.nameFormat === this.nameFormats[1] ? 1 : 0);
            formData.append('LandlordCurrency', this.landlordData.LandlordCurrency);
            formData.append('LandlordMultiCurrency', this.multiCurrency ? 1 : 0);
            formData.append('LandlordSiteLang', this.landlordData.LandlordSiteLang)
            formData.append('LandlordDateFormat', this.dateFormat)
            formData.append('LandlordTimeFormat', this.timeFormat)
            formData.append('LandlordMeasureUnits', this.landlordData.LandlordMeasureUnits)
            formData.append('LandlordBilanStartMonth', this.fiscalMonths.indexOf(this.fiscalMonth) + 1)
            formData.append('LandlordBilanStartDay', this.landlordData.LandlordBilanStartDay)

            formData.append('LandlordExpertMode', this.expertMode ? 1 : 0)

            this.selectedNotifications.map(item => formData.append(item , 1));
            this.selectedOffers.map(item => formData.append(item , 1));



            this.$http.post('landlord/profile/settings', formData).then(({data})=>{
                this.changeLang(this.landlordData.LandlordSiteLang);
                localize(this.landlordData.LandlordSiteLang);
                // useAuth.setDateFormat(this.formatDict[this.optionsLocal.Timeformat])
                // useAuth.setDateFormatFlat(this.optionsLocal.Timeformat)
                useAuth.setLocale(this.landlordData.LandlordSiteLang)
                localStorage.setItem(
                    'userData',
                    JSON.stringify(data.data)
                )
                useAuth.setProfile(data.data)

                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.i18nT(`Configuration Updated!`),
                        icon: 'InfoIcon',
                        variant: 'success',
                    },
                })
                this.$router.push('/');
            }).catch((err) => {
                console.log(err)
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.errorMessage(err),
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })

        }
    }
}
</script>

<style lang="scss">
.rt-custom-checkbox{
    width: 200px;
    margin: 5px 10px;
}

</style>